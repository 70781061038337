<template>
    <div class="business-structure__start-page">
        <h4 class="text-lg">
            {{ header }}
        </h4>

        <div class="business-structure__start-page__content">
            <div>
                <i class="fa-solid fa-wind" />

                <h5>{{ intro }}</h5>

                <p class="mb-2.5">
                    {{ description }}
                </p>

                <el-button
                    type="primary"
                    size="mini"
                    @click="$emit('click')"
                >
                    Add Entity
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {globals} from "Figured/Assets/Modules";

export default {
    name: "BaseStartPage",

    computed: {
        header() {
            return globals.isActiveFarmCommercialManager() ? "Business Structure" : "Farm Business Structure";
        },
        intro() {
            return globals.isActiveFarmCommercialManager() ? "Set up your Business Structure" : "Set up your Farm Business Structure";
        },
        description() {
            return globals.isActiveFarmCommercialManager() ? "Describe all entities and related parties that make up your business" : "Describe all entities and related parties that make up your farm business";
        },
    }
}
</script>

<style lang="scss">
@import 'Assets/Themes/default/_colours.scss';

$icon-size: 60px;

.business-structure__start-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.business-structure__start-page__content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 100%;

    & > div {
        width: 100%;
        text-align: center;
        display: block;
    }

    i.fa-solid {
        width: $icon-size;
        height: $icon-size;
        border-radius: calc($icon-size / 2);
        line-height: $icon-size;
        font-size: calc($icon-size / 2.5);
        background-color: #ECEFF1;
        color: #90A3AE;
        margin-bottom: 20px;
    }

    .el-button {
        margin-top: 20px;
    }

    h5 {
        margin-bottom: 10px
    }

    p {
        font-size: 12px;
        color: $fg-dark-grey;
    }
}
</style>